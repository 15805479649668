* {
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    height: 100vh;
    background-color: #000;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
    width: 100%;
    height: 100vh;
}

.box {
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.andre .logo {
    border-radius:100%;
    width: 200px;
    height: 200px;
    border: solid 5px;
    border-color: #13eeb73e;
}
.andre h1 {
    font-size: 1.8rem;
    font-weight: 500;
    padding-top: 15px;
    color: #13eeb7;
}
.isbrasil {
    position: absolute;
    width: 100%;
    bottom: 20px;
    text-align: center;
}
.isbrasil a {
    font-size: 1.1rem;
    text-decoration: none;
    color: #707070;
    font-weight: 400;
}

.placeholder {
    width: 100%;
    height: 300px;
    background-color: #ccc;
  }